import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
let routes
// 移动端
const routeM = [
  {
    // 首页
    path: '/',
    component: () => import('../views/mobile/layout'),
    redirect: '/chatBot',
    children: [
      {
        // 聊天机器人
        path: '/chatBot',
        name: 'chatBot',
        component: () => import('../views/mobile/Chat')
      },
      {
        // 注册
        path: '/register',
        name: 'Register',
        component: () => import('../views/mobile/Register')
      }
    ]
  },
  { path: '*', redirect: '/' }
]

// PC端
const routePc = [
  {
    // 首页
    path: '/',
    component: () => import('@/views/Layout'),
    redirect: '/ ',
    children: [
      {
        path: '/ ',
        name: 'home',
        component: () => import('../views/Home/index.vue')
      },
      {
        // 个人中心
        path: 'PersonalCenter',
        name: 'PersonalCenter',
        component: () => import('../views/PersonalCenter/index.vue')
      },
      {
        // 聊天机器人
        path: 'chatBot',
        name: 'chatBot',
        component: () => import('../views/Chat/index.vue')
      },
      {
        // 邀请好友
        path: 'invite',
        name: 'invite',
        component: () => import('../views/Invite/index.vue')
      },
      {
        // 领取权益
        path: 'benefits',
        name: 'benefits',
        component: () => import('../views/Benefits/index.vue')
      },
      {
        // 用户协议
        path: 'property',
        name: 'Property',
        component: () => import('../views/Property/index.vue')
      },
      {
        // 隐私政策
        path: 'privacy',
        name: 'Privacy',
        component: () => import('../views/Privacy/index.vue')
      },
      {
        // 会员服务协议
        path: 'membershipAgreement',
        name: 'membershipAgreement',
        component: () => import('../views/membershipAgreement/index.vue')
      },
      {
        // 忘记密码
        path: 'forgotPassword',
        name: 'forgotPassword',
        component: () => import('../views/forgotPassword/index.vue')
      }
    ]
  },
  {
    path: '/health',
    name: 'HealthCheck',
    // 该路由组件可以是一个简单的组件，只返回一个OK
    component: {
      render(h) {
        return h('div', 'OK')
      }
    }
  },
  { path: '*', redirect: '/' }
]

// 判断用户设备
if (
  window.navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  // 移动端
  routes = routeM
} else {
  // PC端
  routes = routePc
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router
