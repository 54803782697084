import request from '@/utils/request'
/**
 * @returns 大模型老用户邀请信息
 */
export const dmxUser = () =>
  request({
    url: '/dmxUser/invite',
    method: 'get'
  })

export const receiveBenefits = (data) =>
  request({
    url: '/dmxUser/receiveBenefits',
    method: 'get',
    data: data
  })
